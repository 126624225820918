<template>
  <div class="container">
    <section class="banner"></section>
    <section>
    </section>
    <section>
      <div class="content">

        <div class="search">
          <el-input
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
              v-model="keywords">
          </el-input>
        </div>
      </div>
    </section>
    <section>
      <ul class="lecturerList">


        <li class="lecturer-item" v-for="(item,index) in pageProps.records" :key="index">
          <router-link :to="{path:'/teacher/detail?id='+item.id}" class="card">
            <div class="thumbnail">
              <img :src="item.avatar?item.avatar:lecturer" alt="">
            </div>
            <div class="info-box">
              <div class="name">{{ item.name }}</div>
              <span class="level">{{ item.level_name }}</span>
            </div>
            <div class="mask">
              <div class="lecturerName">{{ item.name }}</div>
              <div class="introduce">介绍: {{ item.introduction || '暂无' }}</div>
            </div>
          </router-link>
        </li>


      </ul>
      <div class="page-Wrap">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageProps.pageNum"
            :total="pageProps.total"
            :page-size="pageProps.pageSize"
            :current-page="pageProps.pageNum"
            :page-sizes="[5, 10, 50, 100]"
            layout="prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </section>
  </div>
</template>

<script>
import {getNews, getTeachers} from "@/api/syllabus/index";
import lecturer from "@/assets/image/lecturer.png";

export default {
  data() {
    return {
      lecturer: lecturer,
      complete: [''],
      product: [''],
      keywords: '',
      pageProps: {
        records: [],
        total: 0,
        pageSize: 20,
        pageNum: 1
      }
    };
  },
  mounted() {
    this.getList();
  },

  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.initNav();
      },
      // 深度观察监听
      deep: true
    },

  },

  methods: {
    handleSizeChange(size) {
      this.pageProps.pageSize = size;
      this.getList();
    },
    handleCurrentChange(num) {
      this.pageProps.pageNum = num;
      this.getList();
    },
    currentPage3() {

    },
    getList() {

      getTeachers({
        list_rows: this.pageProps.pageSize,
        page: this.pageProps.pageNum
      }).then(res => {

        if (res.code == 200) {
          this.pageProps.total = res.data.lists.total;
          this.pageProps.records = res.data.lists.data;
        }
      })
    },

  }
};
</script>

<style lang="scss" scoped>
.container {
  .banner {
    width: 100%;
    height: 200px;
    background: url('../../assets/image/banner1.png');
  }

  .two-columns {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;

    .item {
      width: 588px;
      height: 130px;
      margin-bottom: 24px;

      .card {
        position: relative;
        background-color: #fff;
        transition: all .3s ease;
        display: inline-block;
        display: flex;
        padding: 20px;
        box-sizing: border-box;

        &:hover {
          transform: translateY(-8px);
          box-shadow: 0 6px 12px 2px #f0f0f0;
        }

        &:hover .content .title {
          color: #266fff;
        }

        .newImage {
          width: 160px;
          height: 90px;
        }

        .content {
          width: calc(100% - 180px);
          height: 100%;
          padding-left: 20px;

          .title {
            font-size: 18px;
            line-height: 30px;
            min-height: 30px;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            overflow: hidden;
            color: rgba(0, 0, 0, .85);
            font-weight: 500;
          }

          .describe {
            line-height: 20px;
            min-height: 20px;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            overflow: hidden;
            color: #999;
          }

          .bottom {
            display: flex;
            justify-content: space-between;
            color: #999;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-direction: row;
            margin-top: 25px;

            .time {

            }

            .el-icon-view {
              font-size: 16px;
            }
          }
        }

      }
    }

    .item2 {
      width: 588px;
      height: 117px;
      margin-bottom: 24px;

      .card2 {
        position: relative;
        background-color: #fff;
        transition: all .3s ease;
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;

        &:hover {
          transform: translateY(-8px);
          box-shadow: 0 6px 12px 2px #f0f0f0;
        }

        &:hover .title {
          color: #266fff;
        }

        .title {
          line-height: 24px;
          min-height: 24px;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          overflow: hidden;
          font-size: 16px;
          font-weight: 500;
          color: #333;
        }

        .card2bottom {
          display: flex;
          justify-content: space-between;
          padding: 20px 0 10px;
          border-bottom: 1px solid #eee;
          color: #999;
          font-size: 16px;
        }

        .card2content {
          display: flex;
          width: 60%;
          justify-content: space-between;

          .number {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .checkbox {
    display: flex;
    border-bottom: 1px dashed #e2e2e2;
    padding: 0 10px;
    height: 62px;
    background: #fff;
    align-items: center;

    span {
      font-size: 14px;
      color: #999;
    }

    /deep/ .el-checkbox-button {
      padding: 0 5px;
      margin: 5px 35px 5px 0;

      cursor: pointer;
      transition: all .3s;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      border: none;
      box-sizing: border-box;
      height: 24px;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      // flex-direction: row;
    }

    /deep/ .el-checkbox-button__inner {
      border: none;
      font-size: 14px;
      color: #999;
      height: 24px;
      box-sizing: border-box;
      line-height: 24px;
      padding: 0 5px;
      border-radius: 4px;

      &:hover {
        color: #266fff;
      }
    }

    /deep/ .is-checked .el-checkbox-button__inner {
      background-color: #266fff;
      color: #fff;
    }

  }

  .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 34px 0 16px;
    box-sizing: border-box;

    .crumbs {
      font-size: 14px;
      color: #999;
    }

    .search {
      width: 220px;
      height: 32px;

      /deep/ .el-input__inner {
        border-radius: 16px;
      }
    }
  }

  .list-box {
    border-radius: 6px;

    .item {
      border-radius: 6px;

      .card {
        display: inline-block;
        background-color: #fff;
        transition: all .3s ease;
        border-radius: 6px;

        &:hover {
          transform: translateY(-4px);
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .04);
        }

        .top {
          height: 100px;
          padding: 0 28px 0 44px;
          background-color: #69708b;
          color: #fff;
          font-size: 28px;
          line-height: 100px;
          min-height: 100px;
          white-space: nowrap;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          overflow: hidden;
          position: relative;
          width: 588px;
          box-sizing: border-box;
          border-radius: 6px 6px 0 0;

          &::after {
            content: "";
            width: 4px;
            height: 28px;
            background-color: #fff;
            position: absolute;
            left: 28px;
            top: 36px;
          }
        }

        .info {
          padding: 28px;
          box-sizing: border-box;
          background: #fff;
          border-radius: 0 0 6px 6px;

          .time {
            color: #999;
            font-size: 18px;
            line-height: 1.5;
          }

          .bottom {
            display: flex;
            justify-content: space-between;

            .text {
              display: flex;
              justify-content: unset;
              align-items: unset;
              flex-direction: row;
              color: #666;
              font-size: 18px;
              line-height: 1.5;
              margin-top: 36px;
            }

            .progress {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              flex-direction: row;

              .num {
                font-size: 26px;
                color: #1ab700;
                margin-right: 14px;
              }
            }
          }
        }
      }
    }
  }
}

.page-Wrap {
  padding: 40px 0;
  text-align: center;
}

.lecturerList {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap; // 添加这一行来实现自动换行

  .lecturer-item {
    width: 180px;
    height: 266px;
    background: #fff;
    position: relative;
    margin-right: 24px;
    margin-bottom: 24px; // 添加这一行来增加每行之间的间距

    &:nth-child(6) {
      margin-right: 0;
    }

    .thumbnail {
      width: 180px;
      height: 210px;

      img {
        width: 180px;
        height: 210px;
      }

    }

    .info-box {
      padding: 10px 10px 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;

      .level {
        margin-left: 20px;
        padding: 2px 5px;
        background: rgba(55, 125, 255, .1);
        border-radius: 4px;
        color: #266fff;
      }

      .name {
        flex: 1;
        line-height: 22px;
        min-height: 22px;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        overflow: hidden;
        color: #333;
      }
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px 10px;
      transition: all .3s ease;
      background-color: rgba(0, 0, 0, .6);
      font-size: 14px;
      color: #fff;
      opacity: 0;

      .introduce {
        margin-top: 16px;
      }
    }

    &:hover .mask {
      opacity: 0.7;
    }
  }
}
</style>
